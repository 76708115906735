import React from 'react';
import UploadFile from "./UploadFile"
import CytoscapeTest from "./CytoscapeTest"
import AppDropDrag from "./AppDropDrag"
//import 'fast-kde';
// eslint-disable-next-line
const App = () => {
	// let d1 = density1d([1, 1, 5, 5, 6, 8]);
	// console.log(d1.points());
	return (
		// <UploadFile />
		// <AppDropDrag />
		<CytoscapeTest />
	)
}

export default App;
